import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import 'src/global.css';




// ----------------------------------------------------------------------

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function App() {
  useScrollToTop();

  return (

    <CacheProvider value={cacheRtl}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </CacheProvider>
  );
}
