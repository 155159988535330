import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import DashboardLayout from 'src/layouts/dashboard';
import Protected from 'src/utils/checkpermission';

export const IndexPage = lazy(() => import('src/pages/app'));
export const EmploeesPage = lazy(() => import('src/pages/users/main'));
export const EmploeesDataPage = lazy(() => import('src/pages/users/user'));
export const AddUserPage = lazy(() => import('src/pages/users/adduser'));

export const Mainstudents = lazy(() => import('src/pages/students/main'))
export const AddStudents = lazy(() => import('src/pages/students/addStudent'))
export const ReportStudents = lazy(() => import('src/pages/evaluation/reports-page'))
export const Students = lazy(() => import('src/pages/students/students'))

// Speacial Edu
export const StudentSE = lazy(() => import('src/pages/se/studentSe'))
export const PEvalStudents = lazy(() => import('src/pages/se/preEvaluation'))
export const StudentPlan = lazy(() => import('src/pages/se/plan'))
export const AddItemToPlan = lazy(() => import('src/pages/se/AddItemToPlan'))
export const AddResponseToPlan = lazy(() => import('src/pages/se/AddResponseToPlan'))
export const SeDepart = lazy(() => import('src/pages/departments/seDepart'))



export const Permission = lazy(() => import('src/pages/permissions/permissions'))
export const AddPermission = lazy(() => import('src/pages/permissions/addPermission'))

export const MainDeparts = lazy(() => import('src/pages/departments/main'))

// SpeachDepart
export const SpeachDepart = lazy(() => import('src/pages/departments/speachDepart'))
export const StudentSpeach = lazy(() => import('src/pages/speach/studentSpeach'))
export const PEvalStudentsSpeach = lazy(() => import('src/pages/speach/preEvaluation'))
export const StudentPlanSpeach = lazy(() => import('src/pages/speach/plan'))
export const AddItemToPlanSpeach = lazy(() => import('src/pages/speach/AddItemToPlan'))
export const AddResponseToPlanSpeach = lazy(() => import('src/pages/speach/AddResponseToPlan'))


export const LoginPage = lazy(() => import('src/pages/login'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const userAuth = secureLocalStorage.getItem('object')
  // const url = new URL(window.location.href)

  // function equalUrls(url1, url2) {
  //   return (
  //     new URL(url1, "http://localhost:3000/").pathname ===
  //     new URL(url2, "http://localhost:3000/").pathname
  //   );
  // }


  let authRouter = []

  if (userAuth) {
    if (userAuth.token) {

      authRouter = [
        {
          element: (
            <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          ),
          children:
            [
              { path: '/', element: <IndexPage /> },
              { path: 'employees', element: <Protected childern={<EmploeesPage />} /> },
              { path: 'employees/employeesdata', element: <Protected childern={<EmploeesDataPage />} /> },
              { path: 'employees/add', element: <Protected childern={<AddUserPage />} /> },
              { path: 'employees/edit/:user_id', element: <Protected childern={<AddUserPage />} /> },
              { path: 'permissions', element: <Protected childern={<Permission />} /> },
              { path: 'permissions/:permission_id', element: <Protected childern={<AddPermission />} /> },
              { path: 'students', element: <Protected childern={<Mainstudents />} /> },
              { path: 'students/studentsadd', element: <Protected childern={<AddStudents />} /> },
              { path: 'students/studentsdata', element: <Protected childern={<Students />} /> },
              { path: 'students/edit/:student_id', element: <Protected childern={<AddStudents />} /> },

              { path: 'students/reports/:student_id', element: <ReportStudents /> },
              { path: 'departs', element: <Protected childern={<MainDeparts />} /> },

              { path: 'departs/se', element: <SeDepart /> },
              { path: 'se/students', element: <StudentSE /> },
              { path: 'se/pevaluation/:student_id', element: <PEvalStudents /> },
              { path: 'se/studentsplan/:student_id', element: <StudentPlan /> },
              { path: 'se/additemtoplan/:plan_id', element: <AddItemToPlan /> },
              { path: 'se/addresponsetoplan/:plan_id', element: <AddResponseToPlan /> },

              { path: 'departs/speach', element: <SpeachDepart /> },
              { path: 'speach/students', element: <StudentSpeach /> },
              { path: 'speach/pevaluation/:student_id', element: <PEvalStudentsSpeach /> },
              { path: 'speach/studentsplan/:student_id', element: <StudentPlanSpeach /> },
              { path: 'speach/additemtoplan/:plan_id', element: <AddItemToPlanSpeach /> },
              { path: 'speach/addresponsetoplan/:plan_id', element: <AddResponseToPlanSpeach /> },

            ]
          ,
        },
        {
          path: '/',
          children: [
            { path: '/', element: <Navigate to="/" /> },
            { path: 'login', element: <Navigate to="/" /> },
            { path: '404', element: <Page404 /> },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },
      ]
    }
  } else {
    authRouter = [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '*',
        element: <Navigate to="/login" replace />,
      },
      {
        path: '/',
        children: [
          { path: '/', element: <Navigate to="/login" /> },
          { path: '*', element: <Navigate to="/login" /> },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/login" replace />,
      },
    ]
  }

  const routes = useRoutes(authRouter)

  return routes;
}
