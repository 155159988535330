import secureLocalStorage from 'react-secure-storage';


export default function Protected({ childern }) {
    const userAuth = secureLocalStorage.getItem('object')
    if (userAuth.user.role === 'super') {
        return childern
    } else {
        window.location.replace('/');
    }

}