import { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import Nav from './nav';
import Main from './main';
import Header from './header';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);

  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Nav sx={{
          backgroundImage: "url('assets/images/bar.png')",
          backgroundRepeat: "round",
        }} openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main sx={{
          backgroundImage: "url('assets/background/backgroundsystem.png')",
          backgroundRepeat: "round",
        }}>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
