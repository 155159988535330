import axios from 'axios'

const url = 'https://khatwatamal-d0bd2f5a735f.herokuapp.com'
// const url = 'http://localhost:5002'                 // comment in production
const config = {
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`
    },
    // withCredentials: true                           // comment in production
}

// const configImage = {
//     headers: { 'Content-Type': 'multipart/form-data' },
//     // withCredentials: true                           // comment in production
// }

//User Api
export const auth = (data) => axios.post(`${url}/admin/login`, data, config)
export const authLogout = () => axios.get(`${url}/admin/logout`, config)
export const fetchUsers = () => axios.get(`${url}/users`, config)
export const getUser = (id) => axios.get(`${url}/users/${id}`, config)
export const updateUser = (id, data) => axios.put(`${url}/users/edit/${id}`, data, config)
export const addUser = (data) => axios.post(`${url}/users`, data, config)
export const getSuperVisor = () => axios.get(`${url}/users/supervisors`, config)
export const getTeacherByDepart = (departName) => axios.get(`${url}/users/teachersbydepart/${departName}`, config)
export const fetchUserRoles = () => axios.get(`${url}/userRoles`, config)


//Permissions Api
export const addPermission = (data) => axios.post(`${url}/permissions`, data, config)
export const fetchPermissions = () => axios.get(`${url}/permissions`, config)
export const updatePermission = (id, data) => axios.put(`${url}/permissions/edit/${id}`, data, config)
export const getPermission = (id) => axios.get(`${url}/permissions/${id}`, config)





//Students Api
export const addStudent = (data) => axios.post(`${url}/students`, data, config)
export const fetchStudents = () => axios.get(`${url}/students`, config)
export const getStudent = (id) => axios.get(`${url}/students/${id}`, config)
export const getStudentName = (id) => axios.get(`${url}/students/stdname/${id}`, config)
export const updateStudent = (id, data) => axios.put(`${url}/students/edit/${id}`, data, config)
export const addTeacherToStudent = (id, data) => axios.put(`${url}/students/addteacher/${id}`, data, config)
export const deleteTeacherStudent = (id, data) => axios.put(`${url}/students/delteacher/${id}`, data, config)
export const fetchStudentForTeacher = (id) => axios.get(`${url}/students/studentforteacher/${id}`, config)




//SECategory Api
export const addSECat = (data) => axios.post(`${url}/secatactitem/category`, data, config)
export const fetchSECat = () => axios.get(`${url}/secatactitem/category`, config)
export const getSeCat = (id) => axios.get(`${url}/secatactitem/category/${id}`, config)
export const updateSECat = (id, data) => axios.put(`${url}/secatactitem/category/edit/${id}`, data, config)

//SEActivity Api
export const addSEAct = (data) => axios.post(`${url}/secatactitem/activity`, data, config)
export const fetchSEAct = () => axios.get(`${url}/secatactitem/activity`, config)
export const getSeAct = (id) => axios.get(`${url}/secatactitem/activity/${id}`, config)
export const getSeActByCat = (id) => axios.get(`${url}/secatactitem/activitybycat/${id}`, config)
export const updateSEAct = (id, data) => axios.put(`${url}/secatactitem/activity/edit/${id}`, data, config)

//SEItems Api
export const addSEItem = (data) => axios.post(`${url}/secatactitem/item`, data, config)
export const fetchSEItem = () => axios.get(`${url}/secatactitem/item`, config)
export const getSeItem = (id) => axios.get(`${url}/secatactitem/item/${id}`, config)
export const delSeItem = (id) => axios.get(`${url}/secatactitem/item/delete/${id}`, config)
export const updateSEItem = (id, data) => axios.put(`${url}/secatactitem/item/edit/${id}`, data, config)
export const getSeItemByAct = (id) => axios.get(`${url}/secatactitem/itemsbyactivity/${id}`, config)

//SpeachCategory Api
export const addSpeachCat = (data) => axios.post(`${url}/speachcatactitem/category`, data, config)
export const fetchSpeachCat = () => axios.get(`${url}/speachcatactitem/category`, config)
export const getSpeachCat = (id) => axios.get(`${url}/speachcatactitem/category/${id}`, config)
export const updateSpeachCat = (id, data) => axios.put(`${url}/speachcatactitem/category/edit/${id}`, data, config)

//SpeachActivity Api
export const addSpeachAct = (data) => axios.post(`${url}/speachcatactitem/activity`, data, config)
export const fetchSpeachAct = () => axios.get(`${url}/speachcatactitem/activity`, config)
export const getSpeachAct = (id) => axios.get(`${url}/speachcatactitem/activity/${id}`, config)
export const getSpeachActByCat = (id) => axios.get(`${url}/speachcatactitem/activitybycat/${id}`, config)
export const updateSpeachAct = (id, data) => axios.put(`${url}/speachcatactitem/activity/edit/${id}`, data, config)

//SpeachItems Api
export const addSpeachItem = (data) => axios.post(`${url}/speachcatactitem/item`, data, config)
export const fetchSpeachItem = () => axios.get(`${url}/speachcatactitem/item`, config)
export const getSpeachItem = (id) => axios.get(`${url}/speachcatactitem/item/${id}`, config)
export const delSpeachItem = (id) => axios.get(`${url}/speachcatactitem/item/delete/${id}`, config)
export const updateSpeachItem = (id, data) => axios.put(`${url}/speachcatactitem/item/edit/${id}`, data, config)
export const getSpeachItemByAct = (id) => axios.get(`${url}/speachcatactitem/itemsbyactivity/${id}`, config)
export const addSpeachPEval = (data) => axios.post(`${url}/speachevaluation/pevaluation`, data, config)
export const printSpeachPEval = (id, date) => axios.get(`${url}/speachevaluation/printreport?id=${id}&date=${date}`, { responseType: 'arraybuffer', headers: { 'Accept': 'application/pdf' } })
export const fetchSpeachPEval = (id) => axios.get(`${url}/speachevaluation/prevalreport/${id}`, config)

//SEPEvalutation Api
export const addSEPEval = (data) => axios.post(`${url}/evaluation/pevaluation`, data, config)
export const printSEPEval = (id) => axios.get(`${url}/evaluation/printreport/${id}`, { responseType: 'arraybuffer', headers: { 'Accept': 'application/pdf' } })
export const fetchSEPEval = (id) => axios.get(`${url}/evaluation/prevalreport/${id}`, config)

//SEPlan Api
export const addSEPlan = (data) => axios.post(`${url}/seplan/addsestudentplan`, data, config)
export const addSEPlanobject = (data) => axios.post(`${url}/seplan/addobject`, data, config)
export const deleteSEItem = (id, data) => axios.put(`${url}/seplan/deleteitem/${id}`, data, config)
export const addSEobjectResponse = (data) => axios.post(`${url}/seplan/addobjectresponse`, data, config)
export const getSEPlan = () => axios.get(`${url}/seplan`, config)
export const getSeObject = (id) => axios.get(`${url}/seplan/object/${id}`, config)
export const getStudentPlan = (id) => axios.get(`${url}/seplan/studentplan/${id}`, config)
export const getSEPlanById = (id) => axios.get(`${url}/seplan/${id}`, config)
export const updateSEPlan = (data) => axios.put(`${url}/seplan/editplan`, data, config)
export const addActivitySEPlan = (id, data) => axios.put(`${url}/seplan/addactivity/${id}`, data, config)
export const deleteSEPlan = (id) => axios.get(`${url}/seplan/delete/${id}`, config)
export const deleteSEObject = (id, data) => axios.put(`${url}/seplan/deleteobject/${id}`, data, config)

//SEReports Api
export const getMonthlyReport = (data) => axios.post(`${url}/reports/semonthlyreport`, data, { responseType: 'arraybuffer', headers: { 'Accept': 'application/pdf' } })
export const getAnnualReport = (data) => axios.post(`${url}/reports/seannualreport`, data, { responseType: 'arraybuffer', headers: { 'Accept': 'application/pdf' } })











export const fetchDepartsName = () => axios.get(`${url}/departsName`, config)
export const fetchJobs = () => axios.get(`${url}/jobs`, config)
export const fetchNationalty = () => axios.get(`${url}/nationalty`, config)








